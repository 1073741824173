import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "EIS Chapters Heading",
    moduleComponentName: "SectionHeadingWithButtons",
    rowProps: {
      className: "py-5 bg-primary text-light",
      noGutters: true,
    },
    moduleProps: {
      sectionHeadingProps: {
        heading: {
          priority: 2,
          style: {
            fontWeight: "bold",
          },
          text:
            "Project planning and environmental impact assessment documents",
        },
        subheading: {
          text: `Select a document to view and download.`,
        },
      },
      buttons: [
        {
          text: "Submissions Report",
          props: {
            variant: "light",
            href: "#submissions-report",
          },
        },
        {
          text: "Final Off-airport EIA",
          props: {
            variant: "light",
            href: "#off-airport-eia",
          },
        },
        {
          text: "Final On-airport EIA",
          props: {
            variant: "light",
            href: "#on-airport-eia",
          },
        },
        {
          text: "Project Overview book",
          props: {
            variant: "light",
            href: "#project-overview-book",
          },
        },
        {
          text: "Main EIS Document",
          props: {
            variant: "light",
            href: "#main-eis-document",
          },
        },
        {
          text: "EIS Appendices",
          props: {
            variant: "light",
            href: "#eis-appendices",
          },
        },
        {
          text: "EIS Technical Papers",
          props: {
            variant: "light",
            href: "#eis-technical-papers",
          },
        },
      ],
    },
    wrapWithContainer: true,
  },
  {
    name: "Submissions Report",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Submissions Report",
      groupHeadingId: "submissions-report",
      chapters: [
        {
          chapter: "-",
          tableFiles: [
            {
              title: "Main Report",
              document: "submissions-report",
            },
          ],
        },
        {
          chapter: "Appendix A",
          tableFiles: [
            {
              title:
                "Where to find response to issues raised in community submissions",
              document: "submissions-report-a",
            },
          ],
        },
        {
          chapter: "Appendix B",
          tableFiles: [
            {
              title:
                "Revised project description and performance outcomes and mitigation measures",
              document: "submissions-report-b",
            },
          ],
        },
        {
          chapter: "Appendix C",
          tableFiles: [
            {
              title: "Overarching Community Communications Strategy",
              document: "submissions-report-c",
            },
          ],
        },
        {
          chapter: "Appendix D",
          tableFiles: [
            {
              title: "Design Guidelines",
              document: "submissions-report-d",
            },
          ],
        },
        {
          chapter: "Appendix E",
          tableFiles: [
            {
              title: "Construction Environmental Management Framework",
              document: "submissions-report-e",
            },
          ],
        },
        {
          chapter: "Appendix F",
          tableFiles: [
            {
              title: "Construction Noise and Vibration Standard",
              document: "submissions-report-f",
            },
          ],
        },
        {
          chapter: "Appendix G",
          tableFiles: [
            {
              title: "Revised Biodiversity Development Assessment Report",
              document: "submissions-report-g",
            },
          ],
        },
        {
          chapter: "Appendix H",
          tableFiles: [
            {
              title: "Revised Aboriginal Cultural Heritage Assessment Report",
              document: "submissions-report-h",
            },
          ],
        },
        {
          chapter: "Appendix I",
          tableFiles: [
            {
              title: "Aboriginal Cultural Heritage Management Plan",
              document: "submissions-report-i",
            },
          ],
        },
        {
          chapter: "Appendix J",
          tableFiles: [
            {
              title: "Aboriginal Archaeological Report",
              document: "submissions-report-j",
            },
          ],
        },
        {
          chapter: "Appendix K",
          tableFiles: [
            {
              title: "Archaeological Research Design",
              document: "submissions-report-k",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Part",
          },
          { text: "Contents" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "Off-airport-EIA",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName:
        "EPBC Act Final Environmental Impact Assessment of off-airport proposed action (EPBC 2020/8687)",
      groupHeadingId: "off-airport-eia",
      chapters: [
        {
          chapter: "1",
          tableFiles: [
            {
              title: "Main Report",
              document: "off-eia-1",
            },
          ],
        },
        {
          chapter: "2",
          tableFiles: [
            {
              title: "Appendix A",
              document: "off-eia-2",
            },
          ],
        },
        {
          chapter: "3",
          tableFiles: [
            {
              title: "Appendices B to G",
              document: "off-eia-3",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Volume",
          },
          { text: "Contents" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "EIA",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName:
        "EPBC Act Final Environmental Impact Assessment of on-airport proposed action (EPBC 2019/8541)",
      groupHeadingId: "on-airport-eia",
      chapters: [
        {
          chapter: "1",
          tableFiles: [
            {
              title: "Main Report",
              document: "eia-1",
            },
          ],
        },
        {
          chapter: "2",
          tableFiles: [
            {
              title: "Appendices A to C",
              document: "eia-2",
            },
          ],
        },
        {
          chapter: "3",
          tableFiles: [
            {
              title: "Appendices D to J",
              document: "eia-3",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Volume",
          },
          { text: "Contents" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "EIS Chapters Group 1",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Project Overview book",
      groupHeadingId: "project-overview-book",
      style: {
        scrollMarginTop: "100px",
      },
      chapters: [
        {
          image: "project-overview-book",
          title: "Project Overview book",
          document: "project-overview-book",
        },
      ],
    },
    wrapWithContainer: true,
  },
  {
    name: "EIS Chapters Group 2",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "Main EIS Document",
      groupHeadingId: "main-eis-document",
      chapters: [
        {
          chapter: "-",
          tableFiles: [
            {
              title: "Executive summary",
              document: "eis-executive-summary",
            },
          ],
        },
        {
          chapter: "Chapter 1",
          tableFiles: [
            {
              title: "Introduction",
              document: "eis-chapter-1",
            },
          ],
        },
        {
          chapter: "Chapter 2",
          tableFiles: [
            {
              title: "Strategic need and justification",
              document: "eis-chapter-2",
            },
          ],
        },
        {
          chapter: "Chapter 3",
          tableFiles: [
            {
              title: "Project location and setting",
              document: "eis-chapter-3",
            },
          ],
        },
        {
          chapter: "Chapter 4",
          tableFiles: [
            {
              title: "Planning and assessment process",
              document: "eis-chapter-4",
            },
          ],
        },
        {
          chapter: "Chapter 5",
          tableFiles: [
            {
              title: "Stakeholder and community engagement",
              document: "eis-chapter-5",
            },
          ],
        },
        {
          chapter: "Chapter 6",
          tableFiles: [
            {
              title: "Project development and alternatives",
              document: "eis-chapter-6",
            },
          ],
        },
        {
          chapter: "Chapter 7",
          tableFiles: [
            {
              title: "Project description - operation",
              document: "eis-chapter-7",
            },
          ],
        },
        {
          chapter: "Chapter 8",
          tableFiles: [
            {
              title: "Project description - construction",
              document: "eis-chapter-8",
            },
          ],
        },
        {
          chapter: "Chapter 9",
          tableFiles: [{ title: "Transport", document: "eis-chapter-9" }],
        },
        {
          chapter: "Chapter 10",
          tableFiles: [
            {
              title: "Noise and vibration",
              document: "eis-chapter-10",
            },
          ],
        },
        {
          chapter: "Chapter 11",
          tableFiles: [
            {
              title: "Biodiversity",
              document: "eis-chapter-11",
            },
          ],
        },
        {
          chapter: "Chapter 12",
          tableFiles: [
            {
              title: "Non-Aboriginal heritage",
              document: "eis-chapter-12",
            },
          ],
        },
        {
          chapter: "Chapter 13",
          tableFiles: [
            {
              title: "Aboriginal heritage",
              document: "eis-chapter-13",
            },
          ],
        },
        {
          chapter: "Chapter 14",
          tableFiles: [
            {
              title: "Flooding, hydrology and water quality",
              document: "eis-chapter-14",
            },
          ],
        },
        {
          chapter: "Chapter 15",
          tableFiles: [
            {
              title: "Groundwater and geology",
              document: "eis-chapter-15",
            },
          ],
        },
        {
          chapter: "Chapter 16",
          tableFiles: [
            {
              title: "Soils and contamination",
              document: "eis-chapter-16",
            },
          ],
        },
        {
          chapter: "Chapter 17",
          tableFiles: [
            {
              title: "Sustainability, climate change and greenhouse gas",
              document: "eis-chapter-17",
            },
          ],
        },
        {
          chapter: "Chapter 18",
          tableFiles: [
            {
              title: "Resource management",
              document: "eis-chapter-18",
            },
          ],
        },
        {
          chapter: "Chapter 19",
          tableFiles: [
            {
              title: "Land use and property",
              document: "eis-chapter-19",
            },
          ],
        },
        {
          chapter: "Chapter 20",
          tableFiles: [
            {
              title: "Landscape and visual",
              document: "eis-chapter-20",
            },
          ],
        },
        {
          chapter: "Chapter 21",
          tableFiles: [
            {
              title: "Social and economic",
              document: "eis-chapter-21",
            },
          ],
        },
        {
          chapter: "Chapter 22",
          tableFiles: [
            {
              title: "Air quality",
              document: "eis-chapter-22",
            },
          ],
        },
        {
          chapter: "Chapter 23",
          tableFiles: [
            {
              title: "Hazard and risk",
              document: "eis-chapter-23",
            },
          ],
        },
        {
          chapter: "Chapter 24",
          tableFiles: [
            {
              title: "Cumulative impacts",
              document: "eis-chapter-24",
            },
          ],
        },
        {
          chapter: "Chapter 25",
          tableFiles: [
            {
              title: "Environmental management and mitigation",
              document: "eis-chapter-25",
            },
          ],
        },
        {
          chapter: "Chapter 26",
          tableFiles: [
            {
              title: "Environmental risk analysis",
              document: "eis-chapter-26",
            },
          ],
        },
        {
          chapter: "Chapter 27",
          tableFiles: [
            {
              title: "Synthesis",
              document: "eis-chapter-27",
            },
          ],
        },
        {
          chapter: "Chapter 28",
          tableFiles: [
            {
              title: "Conclusion",
              document: "eis-chapter-28",
            },
          ],
        },
        {
          chapter: "Chapter 29",
          tableFiles: [
            {
              title: "References",
              document: "eis-chapter-29",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Chapter",
          },
          { text: "Title" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "EIS Chapters Group 3",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "EIS Appendices",
      groupHeadingId: "eis-appendices",
      chapters: [
        {
          chapter: "Appendix A",
          tableFiles: [
            {
              title: "Environmental assessment requirements",
              document: "eis-appendix-a",
            },
          ],
        },
        {
          chapter: "Appendix B",
          tableFiles: [
            {
              title: "Statutory approvals framework",
              document: "eis-appendix-b",
            },
          ],
        },
        {
          chapter: "Appendix C",
          tableFiles: [
            {
              title: "Overarching Community Communication Strategy",
              document: "eis-appendix-c",
            },
          ],
        },
        {
          chapter: "Appendix D",
          tableFiles: [
            {
              title: "Stakeholder and Community Engagement ",
              document: "eis-appendix-d",
            },
          ],
        },
        {
          chapter: "Appendix E",
          tableFiles: [
            {
              title: "Design Guidelines",
              document: "eis-appendix-e",
            },
          ],
        },
        {
          chapter: "Appendix F",
          tableFiles: [
            {
              title: "Construction Environmental Management Framework",
              document: "eis-appendix-f",
            },
          ],
        },
        {
          chapter: "Appendix G",
          tableFiles: [
            {
              title: "Construction Traffic Management Framework",
              document: "eis-appendix-g",
            },
          ],
        },
        {
          chapter: "Appendix H",
          tableFiles: [
            {
              title: "Construction Noise and Vibration Standard",
              document: "eis-appendix-h",
            },
          ],
        },
        {
          chapter: "Appendix I",
          tableFiles: [
            {
              title: "Environmental risk analysis results",
              document: "eis-appendix-i",
            },
          ],
        },
        {
          chapter: "Appendix J",
          tableFiles: [
            {
              title:
                "EPBC Act Draft Environmental Impact Assessment of on-airport proposed action (EPBC 2019/8541)",
              document: "eis-appendix-j",
            },
          ],
        },
        {
          chapter: "Appendix K",
          tableFiles: [
            {
              title:
                "EPBC Act Draft Environmental Impact Assessment of off-airport proposed action (EPBC 2020/8687)",
              document: "eis-appendix-k",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Appendix",
          },
          { text: "Title" },
        ],
      },
    },
    wrapWithContainer: true,
  },
  {
    name: "EIS Chapters Group 4",
    moduleComponentName: "EISChaptersGroup",
    rowProps: {
      className: "mt-3",
      noGutters: true,
    },
    moduleProps: {
      groupHeadingName: "EIS Technical Papers",
      groupHeadingId: "eis-technical-papers",
      chapters: [
        {
          chapter: "Technical Paper 1",
          tableFiles: [
            {
              title: "Transport",
              document: "eis-technical-paper-1",
            },
          ],
        },
        {
          chapter: "Technical Paper 2",
          tableFiles: [
            {
              title: "Noise and vibration (Main Document)",
              document: "eis-technical-paper-2-part-1",
            },
            {
              title: "Appendix A",
              document: "eis-technical-paper-2-part-2",
            },
            {
              title: "Appendix B1",
              document: "eis-technical-paper-2-part-3",
            },
            {
              title: "Appendix B2",
              document: "eis-technical-paper-2-part-4",
            },
            {
              title: "Appendix B3",
              document: "eis-technical-paper-2-part-5",
            },
            {
              title: "Appendix C-F",
              document: "eis-technical-paper-2-part-6",
            },
          ],
        },
        {
          chapter: "Technical Paper 3",
          tableFiles: [
            {
              title: "Biodiversity Development Assessment Report",
              document: "eis-technical-paper-3",
            },
          ],
        },
        {
          chapter: "Technical Paper 4",
          tableFiles: [
            {
              title: "Non-Aboriginal heritage",
              document: "eis-technical-paper-4",
            },
          ],
        },
        {
          chapter: "Technical Paper 5",
          tableFiles: [
            {
              title: "Aboriginal heritage",
              document: "eis-technical-paper-5",
            },
          ],
        },
        {
          chapter: "Technical Paper 6",
          tableFiles: [
            {
              title: "Flooding, hydrology and water quality (Main Document)",
              document: "eis-technical-paper-6-part-1",
            },
            {
              title: "Appendix A-C",
              document: "eis-technical-paper-6-part-2",
            },
            {
              title: "Appendix D",
              document: "eis-technical-paper-6-part-3",
            },
          ],
        },
        {
          chapter: "Technical Paper 7",
          tableFiles: [
            {
              title: "Groundwater",
              document: "eis-technical-paper-7",
            },
          ],
        },
        {
          chapter: "Technical Paper 8",
          tableFiles: [
            {
              title: "Contamination",
              document: "eis-technical-paper-8",
            },
          ],
        },
        {
          chapter: "Technical Paper 9",
          tableFiles: [
            {
              title: "Landscape and visual",
              document: "eis-technical-paper-9",
            },
          ],
        },
        {
          chapter: "Technical Paper 10",
          tableFiles: [
            {
              title: "Social and economic",
              document: "eis-technical-paper-10",
            },
          ],
        },
      ],
      showAsTable: true,
      tableProps: {
        headers: [
          {
            width: "25%",
            text: "Technical Paper",
          },
          { text: "Title" },
        ],
      },
    },
    wrapWithContainer: true,
  },
];

const EISPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default EISPage;
